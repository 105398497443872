<template>
  <b-card class="report-filter">
    <b-row>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_startDate')"
          label-size="sm"
        >
          <b-form-datepicker
            v-model="start_date"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            size="sm"
            reset-button
            :max="finish_date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_finishDate')"
          label-size="sm"
        >
          <b-form-datepicker
            v-model="finish_date"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            size="sm"
            reset-button
            :min="start_date"
            :max="maxDate"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_customerType')"
          label-size="sm"
        >
          <b-form-select
            v-model="user_type"
            :options="filterData.user_type"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_purchaseStatus')"
          label-size="sm"
        >
          <b-form-select
            v-model="status"
            :options="filterData.status"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_platform')"
          label-size="sm"
        >
          <b-form-select
            v-model="platform"
            :options="filterData.platform"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-btn variant="primary" size="sm" class="mr-2" @click="onSubmit">
          Submit
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="resetFilter">
          Reset
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import * as moment from 'moment'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'PaymentsReportFilter',
  data () {
    return {
      maxDate: moment().startOf('day').format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.paymentsReport.filterData',
      start_date: 'reports.paymentsReport.filter.start_date',
      finish_date: 'reports.paymentsReport.filter.finish_date',
      user_type: 'reports.paymentsReport.filter.user_type',
      status: 'reports.paymentsReport.filter.status',
      platform: 'reports.paymentsReport.filter.platform',
      club_member: 'reports.paymentsReport.filter.club_member',
      page: 'reports.paymentsReport.page'
    })
  },
  mounted () {
    this.fetchFilterData()
    if (Object.keys(this.$route.query).length > 0) {
      Object.keys(this.$route.query)
        .forEach((k) => {
          switch (k) {
            case 'start_date':
            case 'finish_date':
              this[k] = this.$route.query[k]
              break
            default:
              break
          }
        })
      this.onSubmit()
    }
  },
  methods: {
    ...mapActions({
      fetchFilterData: 'reports/paymentsReport/fetchFilterData',
      resetFilter: 'reports/paymentsReport/resetFilter'
    }),
    onSubmit () {
      this.page = 1
      this.$nextTick(() => {
        this.$emit('submit')
      })
    }
  }
}
</script>

<style>
  .month-picker-input-container {
    position: initial;
  }
  .month-picker-input {
    margin: 0 !important;
  }
  .month-picker__container {
    z-index: 10;
  }
</style>
